import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit } from '@/utils/list-generators';
import {
  genMenuItemDefault,
  genMenuItemShowHistory
} from '@/utils/list-generators/menu';
import { useCopy } from '@/compositions/copy';
import { genMenuItemCopy } from '@/utils/list-generators/menu';
import { objectPropertyService } from '@/modules/common/api';
import router from '@/router';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';

export function usePropertyItemMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { getNextRoute } = useHistoryStack();
  return {
    genMenu: (propertyItem, defaultValue, objectId, title) => {
      const { id, value, name } = propertyItem;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/EditProperty.vue'),
            props: {
              currentItem: propertyItem,
              onConfirm: value => objectPropertyService.update(id, { value })
            }
          });
        }),
        genMenuItemDefault(
          {
            id: 'set_default',
            icon: '$set_default',
            title: 'Set default'
          },
          {
            click: () => {
              objectPropertyService.update(id, { value: defaultValue });
            }
          }
        ),
        genMenuItemDefault(
          {
            id: 'set_null',
            icon: '$disable',
            title: 'Set null'
          },
          {
            click: () => {
              objectPropertyService.update(id, { value: null });
            }
          }
        ),
        genMenuItemCopy(async () => copyWithAlert(value)),
        genMenuItemShowHistory(async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hec,
              props: {
                bench: 30,
                ids: [id],
                monitoringItem: {
                  name: title,
                  id: objectId,
                  type: 'propertyItem'
                }
              }
            })
          );
        })
      ];
    }
  };
}
