<template>
  <base-list :items="items" />
</template>

<script>
import { genSubheaderItem, genDefaultItem } from '@/utils/list-generators';

import { computed, inject } from 'vue-demi';
import { convertDate } from '@/utils';
import { usePropertyItemMenu } from './propertyItemMenu';
import formatBytes from '@/utils/common/formatBytes';
import { getSchemaPropertyByKey } from '@/provider/utils';

export default {
  name: 'LinkedObjectCardGeneral',
  setup() {
    const linkedObject = inject('linkedObject');

    const { genMenu } = usePropertyItemMenu();

    const genLinkedObjectProperties = obj => {
      const objWithCategories = Object.keys(obj).reduce((acc, key) => {
        const groupName = obj[key]?.groupName;
        if (groupName) {
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(obj[key]);
        }
        return acc;
      }, {});
      return Object.keys(objWithCategories)
        .sort()
        .reduce((acc, key) => {
          acc.push(genSubheaderItem(key));
          acc.splice(
            acc.length,
            0,
            ...objWithCategories[key].map(prop => {
              let isObject = typeof prop.value === 'object';
              let isSize = prop.property === 'SIZE';

              const getValue = value => {
                if (value === null) return 'n/a';
                if (isObject) return JSON.stringify(value);
                if (isSize) return formatBytes(value);
                return value;
              };
              const title = `${prop.humanReadable}: ${getValue(prop.value)}`;
              return genDefaultItem({
                hardLinked: prop.hardLinked,
                showAvatar: false,
                title,
                subTitle: `${convertDate(prop.updatedAt)} by ${prop.userByBy
                  ?.login || 'n/a'}`,
                actions: genMenu(
                  prop,
                  getSchemaPropertyByKey(obj.schema, prop.key)?.defaultValue,
                  obj.id,
                  title
                )
              });
            })
          );
          return acc;
        }, []);
    };

    const items = computed(() => genLinkedObjectProperties(linkedObject.value));

    return { items };
  }
};
</script>

<style></style>
